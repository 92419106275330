import React from 'react';
import classNames from 'classnames';
import SemanticHeader from '../Chapter/semantic-header';
import A from '../Anchor';

const Linklist = (props) => {
  if (!props.Links) return null;

  const componentClass = props.className ? `${props.className}` : 'c-linklist';

  const listContainerClass = classNames({
    [componentClass]: true,
    ...props.Modifiers,
  });

  const listItems = props.Links.map((item, index) => {
    const listItemClass = classNames(item.className, {
      [`${componentClass}__link`]: true,
    });
    const itemAttr = {
      id: item.id,
      href: item.Href,
      lang: item.Language != 'sv' ? item.Language : null,
      className: listItemClass,
      NewTab: item.NewTab,
      hasIcon: item.NewTab,
    };

    if (item.Icon) {
      itemAttr['data-icon'] = item.Icon;
    }

    if (item.onClick) {
      itemAttr.onClick = (e) => {
        if (item.onClick) item.onClick(e);
      };
    }

    return (
      <li key={index} dir={item.Dir}>
        <A {...itemAttr}>
          <span>{item.Text}</span>
        </A>
      </li>
    );
  });

  return (
    <div className={listContainerClass}>
      {props.Heading && (
        <SemanticHeader
          isH3={props.isH3}
          className={`${componentClass}__header`}
        >
          {props.Heading}
        </SemanticHeader>
      )}
      <ul className={`${componentClass}__list`}>
        {listItems}
        {props.ListChildren}
      </ul>
      {props.children}
    </div>
  );
};

Linklist.defaultProps = {
  ListChildren: null,
};

export default Linklist;
